@charset "UTF-8";
/*reset*/
body, h1, h2, h3, h4, h5, h6, p, dl, dt, dd, ul, ol, li, pre, form, fieldset, legend, button, input, textarea, th, td { margin: 0; padding: 0; }
article, aside, figure, footer, header, hgroup, nav, section { display: block; }
ul, ol { list-style: none; }
img { border: 0 none; vertical-align: top; }
table { border-collapse: collapse; border-spacing: 0; }
textarea { resize: none; }
strong, em, i { font-style: normal; font-weight: normal; }
del { text-decoration: line-through; }
input::-ms-clear { display: none !important; }
button[disabled], input[disabled] { cursor: default; }
input{border:none;}
input, select, button, h1, h2, h3, h4, h5, h6 { font-size: 100%; font-weight: normal; font-family: inherit;}
button,
input,
select,
textarea {
  outline: 0;
  border: none;
  font-size: inherit;
  font-family: inherit;
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 100px #fff inset;
  box-shadow: 0 0 0 100px #fff inset;
}
a { text-decoration: none; color: #666;cursor: pointer; }
body { font: 12px/1.5 "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif; color: #333; background: #fff; }
.clearfix { zoom: 1; }
.clearfix:after { content: ''; display: block; height: 0; clear: both; visibility: hidden; }
// *,
// ::after,
// ::before {
//   -webkit-box-sizing: inherit;
//   box-sizing: inherit;
//   -webkit-tap-highlight-color: transparent;
// }
// html {
//   -webkit-box-sizing: border-box;
//   box-sizing: border-box;
//   color: #333;
//   font-family: "Helvetica Neue", Tahoma, Arial, PingFangSC-Regular,
//     "Hiragino Sans GB", "Microsoft Yahei", sans-serif;
//   line-height: 1.2;
//   -webkit-user-select: none;
//   -moz-user-select: none;
//   -ms-user-select: none;
//   user-select: none;
//   -webkit-font-smoothing: antialiased;
//   -ms-touch-action: manipulation;
//   touch-action: manipulation;
//   -webkit-text-size-adjust: none;
//   -moz-text-size-adjust: none;
//   -ms-text-size-adjust: none;
//   text-size-adjust: none;
// }
// body,
// button,
// dd,
// dl,
// ol,
// ul {
//   margin: 0;
//   padding: 0;
// }
// ol,
// ul {
//   list-style: none;
// }
// a {
//   outline: 0;
//   color: inherit;
//   text-decoration: none;
// }
// a,
// img {
//   -webkit-touch-callout: none;
// }
// button,
// input,
// select,
// textarea {
//   outline: 0;
//   border: none;
//   font-size: inherit;
//   font-family: inherit;
// }
// h1,
// h2,
// h3,
// h4,
// h5,
// h6,
// p {
//   margin: 0;
//   font-weight: 400;
// }
// img {
//   max-width: 100%;
// }
// textarea {
//   resize: none;
// }
// select {
//   background-color: transparent;
//   -webkit-appearance: none;
//   -moz-appearance: none;
//   appearance: none;
// }
// input[type="button"],
// input[type="reset"],
// input[type="submit"] {
//   -webkit-appearance: button;
//   -moz-appearance: button;
//   appearance: button;
// }
// input:-webkit-autofill {
//   -webkit-box-shadow: 0 0 0 100px #fff inset;
//   box-shadow: 0 0 0 100px #fff inset;
// }
// body { font: 12px/1.5 "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif; color: #333; background: #fff; }
// .clearfix { zoom: 1; }
// .clearfix:after { content: ''; display: block; height: 0; clear: both; visibility: hidden; }


