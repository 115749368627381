/* 外面盒子样式---自己定义 */
.page_div {
  margin: 20px 10px 20px 0;
  color: #666
}

/* 页数按钮样式 */
.page_div {
  button {
    display: inline-block;
    // min-width: 30px;
    height: 28px;
    line-height: 28px;
    cursor: pointer;
    color: #666;
    font-size: 13px;
    background-color: #f9f9f9;
    border: 1px solid #dce0e0;
    text-align: center;
    margin: 0 4px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  #firstPage,
  #lastPage,
  #nextPage,
  #prePage {
    // width: 50px;
    // color: #0073A9;
    // border: 1px solid #0073A9
  }

  #nextPage,
  #prePage {
    // width: 70px
  }

  .current {
    // background-color: #0073A9;
    // border-color: #0073A9;
    color: #FFF
  }

  /* 页面数量 */
  .totalPages {
    margin: 0 10px
  }

  .totalPages span,
  .totalSize span {
    // color: #0073A9;
    margin: 0 5px
  }

  /*button禁用*/
  button:disabled {
    opacity: .5;
    cursor: no-drop
  }
}

.page_div {
  text-align: center;
  
  #firstPage,
  #lastPage,
  .totalNum,
  .totalList {
    display: none;
  }

  .current {
    bottom: 3px;
    border-bottom: 2px solid $mainBlue;
    -webkit-box-shadow: 0 2px $mainBlue;
    box-shadow: 0 2px $mainBlue;
    color: #fff;
    background: $lightBlue;
    &:hover {
      color: #fff;
    }
  }

  /* 页数按钮样式 */
  button {
    display: inline-block;
    padding: 0 8px;
    margin: 0 7px;
    height: 26px;
    line-height: 26px;
    font-size: 18px;
    background: #fff;
    border-radius: 3px;
    border: none;
    color: #787878;
  }

  button:hover {
    color: $lightBlue;
  }

  /*button禁用*/
  button:disabled {
    cursor: not-allowed;
    &:hover {
      color: #787878;
    }
  }

  #prePage::before {
    content: '';
    width: 9px;
    height: 9px;
    display: inline-block;
    border-top: 1px solid #aaa;
    border-right: 1px solid #aaa;
    -webkit-transform: rotate(225deg);
    -ms-transform: rotate(225deg);
    -o-transform: rotate(225deg);
    transform: rotate(225deg);
  }
  #nextPage::after {
    content: '';
    width: 9px;
    height: 9px;
    display: inline-block;
    border-top: 1px solid #aaa;
    border-right: 1px solid #aaa;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}