.page{
  &-box{
    margin:36px auto 0;
    text-align: center;
    font-size:0;
    &.martop-54{
      margin-top:-40px;
    }
  }
  &-container{
    display:inline-block;
  }
  &-item{
    padding: 0 8px;
    height: 26px;
    line-height: 26px;
    color:#787878;
    font-size:18px;
    display:inline-block;
    background:#fff;
    margin:0 7px;
    border-radius: 3px;
    &.disabled{
      cursor:not-allowed;
      &:hover{
        color:#787878;
      }
    }
    &:hover{
      color:$lightBlue;
  }
    &.cur{
        color:#fff;
        background:$lightBlue;
    }
    &.page-next{
      &:after{
        content:'';
        width:9px;
        height: 9px;
        display:inline-block;
        border-top:1px solid #aaa;
        border-right: 1px solid #aaa;
        transform:rotate(45deg);
        margin-top:-4px;
        vertical-align: middle;
      }
      &:hover:after{
        border-top:1px solid $lightBlue;
        border-right: 1px solid $lightBlue;
      }
    }
    &.page-prev{
      &:before{
        content:'';
        width:9px;
        height: 9px;
        display:inline-block;
        border-top:1px solid #aaa;
        border-right: 1px solid #aaa;
        transform:rotate(225deg);
        margin-top:-4px;
        vertical-align: middle;
      }
      &:hover:before{
        border-top:1px solid $lightBlue;
        border-right: 1px solid $lightBlue;
      }
    }
  }
}