@charset "UTF-8";
/*reset*/
body, h1, h2, h3, h4, h5, h6, p, dl, dt, dd, ul, ol, li, pre, form, fieldset, legend, button, input, textarea, th, td {
  margin: 0;
  padding: 0;
}

article, aside, figure, footer, header, hgroup, nav, section {
  display: block;
}

ul, ol {
  list-style: none;
}

img {
  border: 0 none;
  vertical-align: top;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

textarea {
  resize: none;
}

strong, em, i {
  font-style: normal;
  font-weight: normal;
}

del {
  text-decoration: line-through;
}

input::-ms-clear {
  display: none !important;
}

button[disabled], input[disabled] {
  cursor: default;
}

input {
  border: none;
}

input, select, button, h1, h2, h3, h4, h5, h6 {
  font-size: 100%;
  font-weight: normal;
  font-family: inherit;
}

button,
input,
select,
textarea {
  outline: 0;
  border: none;
  font-size: inherit;
  font-family: inherit;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 100px #fff inset;
  box-shadow: 0 0 0 100px #fff inset;
}

a {
  text-decoration: none;
  color: #666;
  cursor: pointer;
}

body {
  font: 12px/1.5 "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
  color: #333;
  background: #fff;
}

.clearfix {
  zoom: 1;
}

.clearfix:after {
  content: '';
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

.mt20 {
  margin-top: 20px;
}

.mt15 {
  margin-top: 15px;
}

.mt10 {
  margin-top: 10px;
}

.mt5 {
  margin-top: 5px;
}

.mt0 {
  margin-top: 0px;
}

.page-box {
  margin: 36px auto 0;
  text-align: center;
  font-size: 0;
}

.page-box.martop-54 {
  margin-top: -40px;
}

.page-container {
  display: inline-block;
}

.page-item {
  padding: 0 8px;
  height: 26px;
  line-height: 26px;
  color: #787878;
  font-size: 18px;
  display: inline-block;
  background: #fff;
  margin: 0 7px;
  border-radius: 3px;
}

.page-item.disabled {
  cursor: not-allowed;
}

.page-item.disabled:hover {
  color: #787878;
}

.page-item:hover {
  color: #0b82f0;
}

.page-item.cur {
  color: #fff;
  background: #0b82f0;
}

.page-item.page-next:after {
  content: '';
  width: 9px;
  height: 9px;
  display: inline-block;
  border-top: 1px solid #aaa;
  border-right: 1px solid #aaa;
  transform: rotate(45deg);
  margin-top: -4px;
  vertical-align: middle;
}

.page-item.page-next:hover:after {
  border-top: 1px solid #0b82f0;
  border-right: 1px solid #0b82f0;
}

.page-item.page-prev:before {
  content: '';
  width: 9px;
  height: 9px;
  display: inline-block;
  border-top: 1px solid #aaa;
  border-right: 1px solid #aaa;
  transform: rotate(225deg);
  margin-top: -4px;
  vertical-align: middle;
}

.page-item.page-prev:hover:before {
  border-top: 1px solid #0b82f0;
  border-right: 1px solid #0b82f0;
}

/* 外面盒子样式---自己定义 */
.page_div {
  margin: 20px 10px 20px 0;
  color: #666;
}

/* 页数按钮样式 */
.page_div {
  /* 页面数量 */
  /*button禁用*/
}

.page_div button {
  display: inline-block;
  height: 28px;
  line-height: 28px;
  cursor: pointer;
  color: #666;
  font-size: 13px;
  background-color: #f9f9f9;
  border: 1px solid #dce0e0;
  text-align: center;
  margin: 0 4px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.page_div .current {
  color: #FFF;
}

.page_div .totalPages {
  margin: 0 10px;
}

.page_div .totalPages span,
.page_div .totalSize span {
  margin: 0 5px;
}

.page_div button:disabled {
  opacity: .5;
  cursor: no-drop;
}

.page_div {
  text-align: center;
  /* 页数按钮样式 */
  /*button禁用*/
}

.page_div #firstPage,
.page_div #lastPage,
.page_div .totalNum,
.page_div .totalList {
  display: none;
}

.page_div .current {
  bottom: 3px;
  border-bottom: 2px solid #599bf8;
  -webkit-box-shadow: 0 2px #599bf8;
  box-shadow: 0 2px #599bf8;
  color: #fff;
  background: #0b82f0;
}

.page_div .current:hover {
  color: #fff;
}

.page_div button {
  display: inline-block;
  padding: 0 8px;
  margin: 0 7px;
  height: 26px;
  line-height: 26px;
  font-size: 18px;
  background: #fff;
  border-radius: 3px;
  border: none;
  color: #787878;
}

.page_div button:hover {
  color: #0b82f0;
}

.page_div button:disabled {
  cursor: not-allowed;
}

.page_div button:disabled:hover {
  color: #787878;
}

.page_div #prePage::before {
  content: '';
  width: 9px;
  height: 9px;
  display: inline-block;
  border-top: 1px solid #aaa;
  border-right: 1px solid #aaa;
  -webkit-transform: rotate(225deg);
  -ms-transform: rotate(225deg);
  -o-transform: rotate(225deg);
  transform: rotate(225deg);
}

.page_div #nextPage::after {
  content: '';
  width: 9px;
  height: 9px;
  display: inline-block;
  border-top: 1px solid #aaa;
  border-right: 1px solid #aaa;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

body.body-fixed {
  position: fixed;
  width: 100%;
}

.main {
  width: 1200px;
  margin: 0 auto;
}

.index_tab {
  background: #439bff;
  width: 1200px;
  margin: 0 auto 8px;
}

.index_tab_item {
  float: left;
  line-height: 35px;
  position: relative;
  width: 100px;
  text-align: center;
}

.index_tab_item:first-child {
  margin-left: 0;
}

.index_tab_item a {
  color: #fff;
  font-size: 16px;
  font-weight: bold;
}

.index_tab_item:hover, .index_tab_item.cur {
  background: #0067dc;
}

.index_tab_item:hover .index_secTab_box {
  display: block;
}

.index_tab_item .index_secTab_item a {
  font-size: 14px;
  font-weight: normal;
  color: #494949;
}

.index_tab_item .index_secTab_item a:hover {
  color: #599bf8;
}

.index_secTab_box {
  position: absolute;
  display: none;
  z-index: 3;
  background: #fff;
  width: 100px;
  left: 0px;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.1);
}

.index_secTab_item {
  line-height: 30px;
  text-align: center;
}

.index_nav_box {
  float: left;
  width: 232px;
  height: 360px;
  position: relative;
  z-index: 2;
  background: #439bff;
}

.index_nav_item {
  cursor: pointer;
  width: 100%;
  height: 40px;
  text-align: center;
  color: #fffefe;
  line-height: 40px;
  font-size: 16px;
}

.index_nav_item:first-child {
  background: #3168c5;
}

.index_nav_item:hover {
  font-size: bold;
  background: #3168c5;
}

.index_nav_item:hover .index_secNav_box {
  display: block;
}

.index_secNav_box {
  position: absolute;
  display: none;
  float: left;
  left: 232px;
  top: 0;
  width: 280px;
  height: 360px;
  text-align: left;
  background: #fff;
  padding-top: 20px;
  box-sizing: border-box;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.index_secNav_item {
  margin-left: 30px;
  line-height: 36px;
  display: inline-block;
  width: 110px;
}

.index_secNav_item:nth-child(2n) {
  margin-left: 10px;
}

.index_secNav_item a {
  color: #333;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  white-space: nowrap;
  display: inline-block;
  width: 110px;
  line-height: 28px;
  line-height: 28px;
  letter-spacing: 2px;
}

.index_secNav_item:hover a {
  color: #599bf8;
}

.message-dialog {
  position: fixed;
  width: 397px;
  height: 50px;
  border: 1px solid #66c33a;
  background: #f1f9eb;
  line-height: 48px;
  box-sizing: border-box;
  top: -80px;
  left: 0;
  right: 0;
  margin: 0 auto;
  border-radius: 6px;
  transition: all 0.3s ease;
  z-index: 103;
}

.message-dialog.success {
  border: 1px solid #66c33a;
  background: #f1f9eb;
}

.message-dialog.error {
  border-color: #f1b4b4;
  background: #f7e5e5;
  color: #ec7373;
}

.message-dialog.error .message-text:before {
  background: url(../images/img/icon-error.png);
}

.message-dialog.warning {
  border-color: #faecd8;
  background: #fdf6ec;
  color: #e6a23c;
}

.message-dialog.warning .message-text:before {
  background: url(../images/img/icon-warning.png);
}

.message-dialog.notice {
  border-color: #ebeef5;
  background: #edf2fc;
  color: #909399;
}

.message-dialog.notice .message-text:before {
  background: url(../images/img/icon-notice.png);
}

.message-dialog.show {
  top: 50px;
  transition: all 0.3s ease;
}

.message-text {
  margin-left: 15px;
  font-size: 14px;
}

.message-text:before {
  content: '';
  width: 16px;
  height: 16px;
  display: inline-block;
  background: url(../images/img/icon-success.png) no-repeat;
  vertical-align: middle;
  margin-right: 10px;
}

.el-loading-mask {
  position: absolute;
  z-index: 100;
  background-color: rgba(255, 255, 255, 0.9);
  margin: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: opacity .3s;
}

.el-loading-mask.hide {
  display: none;
}

.el-loading-spinner {
  top: 50%;
  margin-top: -21px;
  width: 100%;
  text-align: center;
  position: absolute;
}

.el-loading-spinner .circular {
  height: 42px;
  width: 42px;
  animation: loading-rotate 2s linear infinite;
}

.el-loading-spinner .path {
  animation: loading-dash 1.5s ease-in-out infinite;
  stroke-dasharray: 90, 150;
  stroke-dashoffset: 0;
  stroke-width: 2;
  stroke: #409eff;
  stroke-linecap: round;
}

@keyframes loading-rotate {
  100% {
    transform: rotate(1turn);
  }
}

@keyframes loading-dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -40px;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -120px;
  }
}

.footer_box {
  background-color: #191c20;
}

.footer_wrapper {
  width: 1200px;
  margin: 0 auto;
}

.footer_head {
  color: #fff;
  font-size: 24px;
  line-height: 1;
  padding: 22px 0 25px;
}

.footer_link_item {
  color: #666;
  font-size: 16px;
  margin-right: 40px;
}

.footer_link_item:hover {
  color: #999;
}

.footer_content {
  padding-top: 16px;
  color: #999;
  line-height: 34px;
  font-size: 16px;
  width: 524px;
  float: left;
  margin-right: 50px;
}

.footer_content a {
  color: #999;
}

.footer_content a:hover {
  color: #fff;
}

.footer_QRcode_item {
  float: left;
  margin-left: 28px;
  margin-top: 25px;
}

.footer_QRcode_item:first-child {
  margin-left: 0;
}

.footer_QRcode_title {
  text-align: center;
  margin: 18px 0 30px;
  font-size: 14px;
  line-height: 1;
  color: #666;
}

.footer_qrcode {
  width: 88px;
  height: 90px;
}

.footer_yyzz {
  float: left;
  margin-top: 40px;
  margin-left: 55px;
}

.footer_yyzz img {
  width: 64px;
  height: 91px;
  vertical-align: bottom;
}

.footer_yyzz_title {
  font-size: 14px;
  color: #fff;
  line-height: 1;
  padding-top: 7px;
}

.footer_zgzs {
  float: left;
  margin-left: 10px;
  margin-top: 96px;
}

.footer_Copyright {
  background-color: #000000;
  height: 70px;
  color: #666;
  font-size: 16px;
  text-align: center;
  width: 100%;
  line-height: 70px;
}

.search_box {
  height: 90px;
  font-size: 0;
  width: 1200px;
  margin: 0 auto;
}

.search_logo {
  width: 136px;
  height: 52px;
  margin: 19px 50px 18px 0;
}

.search_main {
  display: inline-block;
  width: 540px;
  border: 1px solid #599bf8;
  margin: 25px 0 25px 74px;
}

.search_main_input {
  font-size: 16px;
  padding: 0 20px;
  width: 412px;
  line-height: 38px;
  box-sizing: border-box;
  vertical-align: top;
}

.search_main_btn {
  background: #599bf8;
  color: #fff;
  width: 128px;
  height: 38px;
  line-height: 38px;
  text-align: center;
  display: inline-block;
  font-size: 18px;
}

.search_main_btn:hover {
  background: #287ef6;
}

.search_main_phone {
  font-family: 'MicrosoftYaHei';
  font-size: 16px;
  font-weight: bold;
  font-style: italic;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #3168c5;
  display: inline-block;
  width: 318px;
  line-height: 26px;
  text-align: right;
  position: relative;
  margin-left: 36px;
  margin-top: 16px;
  vertical-align: top;
}

.search_main_phone:before {
  content: '';
  width: 57px;
  height: 47px;
  display: inline-block;
  position: absolute;
  background: url(../images/phone.png);
  left: 40px;
}

.search_main_phone span {
  display: inline-block;
}

.c {
  clear: both;
}

.partners_box {
  background-color: #23272b;
  padding-bottom: 49px;
}

.partners_wrapper {
  width: 1200px;
  margin: 0 auto;
}

.partners_head {
  font-size: 24px;
  line-height: 80px;
  color: #fff;
  width: 1200px;
  margin: 0 auto;
}

.partners_item {
  float: left;
  width: 200px;
  opacity: .4;
  text-align: center;
  height: 64px;
  border-right: 1px solid #000;
  border-top: 1px solid #000;
  box-sizing: border-box;
}

.partners_item:nth-child(1) {
  border-top: none;
}

.partners_item:nth-child(2) {
  border-top: none;
}

.partners_item:nth-child(3) {
  border-top: none;
}

.partners_item:nth-child(4) {
  border-top: none;
}

.partners_item:nth-child(5) {
  border-top: none;
}

.partners_item:nth-child(6) {
  border-top: none;
}

.partners_item:nth-child(7) {
  line-height: 64px;
}

.partners_item:nth-child(7) .partners_img {
  vertical-align: bottom;
}

.partners_item:nth-child(8) {
  line-height: 64px;
}

.partners_item:nth-child(8) .partners_img {
  vertical-align: bottom;
}

.partners_item:nth-child(9) {
  line-height: 64px;
}

.partners_item:nth-child(9) .partners_img {
  vertical-align: bottom;
}

.partners_item:nth-child(10) {
  line-height: 64px;
}

.partners_item:nth-child(10) .partners_img {
  vertical-align: bottom;
}

.partners_item:nth-child(11) {
  line-height: 64px;
}

.partners_item:nth-child(11) .partners_img {
  vertical-align: bottom;
}

.partners_item:nth-child(12) {
  line-height: 64px;
}

.partners_item:nth-child(12) .partners_img {
  vertical-align: bottom;
}

.partners_item:nth-child(6) {
  border-right: none;
}

.partners_item:nth-child(12) {
  border-right: none;
}

.partners_img {
  vertical-align: middle;
}

.hot_course {
  margin-top: 35px;
}

.hot_course .hot_head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 40px;
  border-bottom: 1px solid #e5e5e5;
}

.hot_course .hot_head .hot_name {
  font-size: 20px;
  height: 26px;
  line-height: 26px;
  margin: 7px 0;
  padding-left: 10px;
  color: #050505;
  border-left: 4px solid #599bf8;
}

.hot_course .hot_head .hot_menu {
  display: flex;
  flex-direction: row;
  font-size: 16px;
  color: #666666;
  align-items: center;
}

.hot_course .hot_head .hot_menu .hot_menu_item {
  margin-right: 4px;
  width: 16px;
  height: 16px;
  line-height: 16px;
  text-align: center;
  background-color: #e5e5e5;
  border-radius: 2px;
  cursor: pointer;
}

.hot_course .hot_head .hot_menu .next {
  margin-right: 10px;
}

.hot_course .hot_head .hot_menu .disabled {
  cursor: default;
  opacity: 0.6;
}

.hot_course .hot_container {
  height: 184px;
  width: 100%;
  padding: 10px 0 20px;
  position: relative;
}

.hot_course .hot_container .course_item {
  margin-top: 5px;
  width: 224px;
  margin-right: 20px;
}

.hot_course .hot_container .course_item:hover .course_des {
  color: #599bf8;
}

.hot_course .hot_container .course_item .course_img_box {
  width: 224px;
  height: 158px;
  position: relative;
  z-index: 1;
  transition: all .2s linear;
}

.hot_course .hot_container .course_item .course_img_box:hover {
  transform: translate3d(0, -2px, 0);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
}

.hot_course .hot_container .course_item .course_img_box img {
  width: 100%;
  height: 100%;
}

.hot_course .hot_container .course_item .course_des {
  font-size: 14px;
  color: #666666;
  line-height: 32px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  white-space: nowrap;
}

.hot_course .hot_container .course_item .course_des:hover {
  color: #599bf8;
}

.hot_course .hot_container .swiper-slide:last-child .course_item {
  margin-right: 0;
}

.mt20 {
  margin-top: 20px;
}

.mt15 {
  margin-top: 15px;
}

.mt10 {
  margin-top: 10px;
}

.mt5 {
  margin-top: 5px;
}

.mt0 {
  margin-top: 0px;
}

.main_list {
  margin-top: 13px;
  padding-bottom: 15px;
  border-bottom: 1px solid #d2d2d2;
  display: block;
}

.main_list:nth-last-child(1) {
  border-width: 0;
}

.main_list_img {
  float: left;
  width: 196px;
  height: 114px;
}

.main_list_cont {
  cursor: pointer;
  float: left;
  margin-left: 10px;
}

.main_list_cont p {
  font-size: 14px;
}

.main_list_cont:hover .main_list_title {
  color: #599bf8;
}

.main_list_title {
  font-size: 18px;
  max-width: 480px;
}

.main_list_title .tage {
  display: inline-block;
  color: #fff;
  width: 80px;
  text-align: center;
  line-height: 31px;
  background-color: #599bf8;
  margin-right: 10px;
  font-size: 14px;
}

.main_list_time .sum {
  display: inline-block;
  color: #599bf8;
  padding-left: 5px;
}

.main_list_price {
  color: #de532a;
}

.main_list_btn {
  float: right;
  margin: 30px 15px 0 0;
  display: inline-block;
  text-align: center;
  width: 122px;
  font-size: 14px;
  line-height: 42px;
  border-radius: 21px;
  border: solid 1px #d2d2d2;
  transition: all .3s ease;
}

.main_list_btn:hover {
  background-color: #599bf8;
  color: #fff;
  border: solid 1px #599bf8;
}

.centerTop {
  color: #fff;
  padding: 14px 0;
  background-color: #599bf8;
}

.centerTop_left {
  float: left;
  cursor: default;
}

.centerTop_right {
  float: right;
}

.centerTop_right .smallBtn {
  display: inline-block;
  text-align: center;
  font-size: 18px;
  width: 122px;
  height: 42px;
  line-height: 42px;
  background-color: #de532a;
  color: #fff;
  cursor: pointer;
  box-shadow: 0px 2px 2px 0px rgba(18, 70, 145, 0.58);
  border-radius: 21px;
  margin-top: 30px;
}

.centerTop_right .smallBtn:hover {
  opacity: .8;
}

.centerTop_right .integral.gray {
  background-color: #aaa;
}

.centerTop_tx {
  width: 107px;
  height: 107px;
  display: inline-block;
  vertical-align: middle;
  border: 3px solid #fff;
  border-radius: 50%;
  box-sizing: border-box;
}

.centerTop_name {
  font-size: 20px;
  margin-left: 20px;
}

.centerTop_time {
  margin-left: 22px;
}

.centerTop_li {
  cursor: default;
  float: left;
  width: 112px;
  font-size: 18px;
  position: relative;
  padding: 10px 0 0 10px;
}

.centerTop_li:nth-child(2) .rule {
  left: -88px;
  width: 269px;
}

.centerTop_li:nth-child(2) .rule::before {
  left: 127px;
}

.centerTop_li:nth-child(3) .rule {
  left: -63px;
  width: 233px;
}

.centerTop_li:nth-child(3) .rule::before {
  left: 105px;
}

.centerTop_li .rule {
  text-align: justify;
  display: none;
  position: absolute;
  top: 115px;
  left: -77px;
  width: 244px;
  padding: 5px 10px;
  color: black;
  font-size: 14px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: -1px 2px 5px 0px rgba(0, 0, 0, 0.25);
  z-index: 9;
}

.centerTop_li .rule.showRule {
  display: block;
}

.centerTop_li .rule:before {
  content: "";
  position: absolute;
  top: -5px;
  left: 118px;
  border-bottom: 5px solid #fff;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}

.centerTop_li p {
  line-height: 35px;
}

.centerTop_li .icon {
  cursor: pointer;
  display: inline-block;
  margin-left: 23px;
}

.centerTop_li:after {
  position: absolute;
  bottom: 4px;
  right: 15px;
  content: "";
  height: 105px;
  border-left: 1px solid #fff;
  transform: rotate(20deg);
}

.centerTop_li:nth-last-child(2):after {
  height: 0;
}

.center_content {
  margin: 20px auto 0 !important;
}

.center_left_nav {
  width: 158px;
  background-color: #f5f5f5;
}

.center_left_nav a {
  cursor: pointer;
  display: block;
  text-align: center;
  width: 158px;
  line-height: 55px;
  font-size: 20px;
  color: #000;
}

.center_left_nav a.cur {
  color: #fff;
  background-color: #599bf8;
}

.center_left_nav a:hover {
  color: #fff;
  background-color: #599bf8;
}

.head_box {
  height: 60px;
  line-height: 58px;
}

.head_wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  -webkit-box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.1);
  background: #fff;
  font-size: 14px;
  color: #505153;
  z-index: 3;
}

.head_container {
  width: 1200px;
  margin: 0 auto;
}

.head_container a .nav_logo {
  float: left;
  margin: 10px 0;
  height: 40px;
}

.head_container .head_link_box2 {
  float: left;
  font-size: 18px;
  line-height: 55px;
  color: #050505;
}

.head_container .head_link_box2 .head_link_item2 {
  margin-left: 30px;
}

.head_container .head_link_box2 .head_link_item2:hover {
  color: #599bf8;
}

.head_xiaoxun {
  float: left;
}

.head_link_box {
  float: left;
  text-align: center;
  margin-left: 110px;
}

.head_link_item:hover {
  color: #3168c5;
}

.head_link_item:before {
  content: '';
  width: 1px;
  height: 12px;
  display: inline-block;
  background: #505153;
  margin: 0 9px;
}

.head_link_item:first-child:before {
  width: 0;
  margin: 0;
}

.nav_checkLoginBox {
  float: right;
}

.nav_checkLoginBox .navs {
  float: left;
  padding-left: 10px;
}

.nav_checkLoginBox .nav {
  float: left;
  padding-left: 10px;
  box-sizing: border-box;
}

.nav_checkLoginBox .nav:hover {
  border-left: 1px solid #d2d2d2;
  border-right: 1px solid #d2d2d2;
  background-color: #fbfbfb;
}

.nav_checkLoginBox .nav:hover .down-list {
  display: block;
}

.nav_checkLoginBox .nav:hover .down_icon {
  transform: rotate(90deg);
}

.nav_checkLoginBox .nav_link {
  display: block;
  position: relative;
  color: #599bf8;
  cursor: pointer;
}

.nav_checkLoginBox .nav_link em {
  display: inline-block;
  height: 11px;
  border-right: 1px solid #599bf8;
  margin-left: 5px;
}

.mine-nav {
  position: relative;
  padding-right: 20px;
}

.mine-nav .xhdLogin {
  margin-top: 5px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
}

.mine-nav .down_icon {
  display: block;
  transform: rotate(-90deg);
  position: absolute;
  top: 1px;
  right: -12px;
  transition: all 0.4s ease;
}

.mine-nav .down-list {
  display: none;
  position: absolute;
  top: 58px;
  left: 0;
  z-index: 9;
  width: 143px;
  text-align: center;
  background-color: #fbfbfb;
  padding: 5px 0 10px;
  border-left: 1px solid #d2d2d2;
  border-right: 1px solid #d2d2d2;
  border-bottom: 1px solid #d2d2d2;
  box-sizing: border-box;
}

.mine-nav .down-list .list {
  line-height: 30px;
  cursor: pointer;
}

.mine-nav .down-list .list:hover .list-link {
  color: #599bf8;
  text-decoration: underline;
}

#msgTips {
  position: fixed;
  top: 20px;
  left: 50%;
  z-index: 99999999;
  transform: translate(-50%, -150%);
  padding: 12px;
  width: 80%;
  max-width: 380px;
  border: 1px solid #fedddd;
  border-radius: 3px;
  background-color: #fee;
  font-size: 16px;
  color: #fa5555;
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s, transform 0.4s;
}

#msgTips.success {
  color: #67c23a;
  background-color: #f0f9eb;
  border-color: #e1f3d8;
}

#msgTips.fade-in {
  opacity: 1;
  transform: translate(-50%, 0);
}

.noData {
  margin-top: 30px;
  text-align: center;
  line-height: 30px;
  font-size: 16px;
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
}

.noData .icon {
  display: block;
  margin-right: 10px;
}
