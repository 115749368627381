
@mixin ellipsis{
  word-break: keep-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@mixin position($x, $y){
  background-position:$x*(-1)+0px ($y-1)*(-40)+0px;
}
@mixin mask(){
    position: relative;
    &:after{
        content:'';
        display:block;
        position: absolute;
        width:100%;
        height: 100%;
        top:0;
        left:0;
        right: 0;
        bottom: 0;
        background:#000;
        opacity: 0.3;
        cursor: pointer;
    }
    &:hover:after{
        transition:opacity ease .3s;
        opacity: 0;
    }
}
@mixin  smallBtn($w,$h,$bgc) {
  display:inline-block;
  text-align: center;
  font-size: 18px;
  width: $w;
  height:$h;
  line-height: $h;
	background-color: $bgc;
  color: $white;
  cursor: pointer;
  &:hover{
    opacity: .8;
  }
}
@mixin link($color1:#999,$color2:$mainBlue){
  cursor: pointer;
  color:$color1;
  &:hover{
    color:$color2;
  }
}
@mixin nolink($color1:#999,$color2:$mainBlue){
  color:$color1;
  &:hover{
    color:$color2;
  }
}
@mixin hoverTransform(){
  position: relative;
  z-index: 1;
  transition: all .2s linear;
  &:hover{
    transform: translate3d(0,-2px,0);
    box-shadow: 0 15px 30px rgba(0,0,0,.1);
  }
}
@mixin ellipsis($num: 1) {
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;

  @if $num==1 {
    white-space: nowrap;
  } @else {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $num;
  }
}
@mixin richTxt(){
  font-size: 16px;
  line-height: 28px;
  color:$fontGray;
  padding-bottom: 30px;
  p{
    font-size: 16px;
    line-height: 28px;
    color:$fontGray;
    margin:20px 0;
  }
  h1{
    font-size: 22px;
    color:#333;
  }
  h2{
    font-size:20px;
    color:#333;
  }
  h3{
    font-size: 18px;
    color:#333;
  }
  h4{
    font-size: 16px;
    color:#333;
  }
  img{
    width:100%;
    max-width: 100%;
  }
}

@mixin oneborder ($color,$type){

  @if $type == top{
    border-top: 1px solid $color;
  }@else if $type == left{
    border-left: 1px solid $color;
  }@else if $type == right{
    border-right: 1px solid $color;
  }@else if $type == bottom{
    border-bottom: 1px solid $color;
  }@else if $type == all{
    border: 1px solid $color;
  }
}

$i: 20;
@while $i >= 0 {
  .mt#{$i} { margin-top: 1px * $i; }
  $i: $i - 5;
}