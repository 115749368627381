@import "./core/reset";
@import "./core/color";
@import "./core/method";
@import "./core/page";
@import "./core/paging";

body {
  &.body-fixed {
    position: fixed;
    width: 100%;
  }
}

.main {
  width: 1200px;
  margin: 0 auto;
}

// .head {
//   &_box {
//     height: 40px;
//     line-height: 40px;
//   }

//   &_wrapper {
//     position: fixed;
//     top: 0;
//     left: 0;
//     width: 100%;
//     box-shadow: 0px 3px 3px rgba(0, 0, 0, .1);
//     // border-bottom: 1px solid #d2d2d2;
//     background: #fff;
//     font-size: 14px;
//     color: #505153;
//     z-index: 4;
//   }

//   &_container {
//     width: 1200px;
//     margin: 0 auto;
//   }

//   &_xiaoxun {
//     float: left;
//   }

//   &_onlineChat {
//     float: right;
//     background: $orange2;
//     color: #fff;
//     padding: 0 10px;
//     height: 22px;
//     line-height: 22px;
//     border-radius: 4px;
//     margin-top: 9px;
//     cursor: pointer;

//     &:hover {
//       background: darken($orange2, 10%);
//     }
//   }

//   &_link {
//     &_box {
//       float: left;
//       text-align: center;
//       margin-left: 110px;
//       // width: 1020px;
//     }

//     &_item {
//       &:hover {
//         color: $mainBlue;
//       }

//       &:before {
//         content: '';
//         width: 1px;
//         height: 12px;
//         display: inline-block;
//         background: #505153;
//         margin: 0 9px;
//       }

//       &:first-child:before {
//         width: 0;
//         margin: 0;
//       }
//     }
//   }
// }

//导航
.index {
  &_tab {
      background: $lightBlue2;
      // margin-bottom: 8px;
      width: 1200px;
      margin: 0 auto 8px;
      &_item {
          float: left;
          // margin-left:40px;
          line-height: 35px;
          position: relative;
          width: 100px;
          text-align: center;

          &:first-child {
              margin-left: 0;
          }

          a {
              color: #fff;
              font-size: 16px;
              font-weight: bold;
          }

          &:hover,
          &.cur {
              background: darken($lightBlue2, 20%);
              // a{
              //     // color:$mainBlue;
              // }
          }

          &:hover {

              .index_secTab_box {
                  display: block;
              }
          }

          .index_secTab_item a {
              font-size: 14px;
              font-weight: normal;
              color: #494949;

              &:hover {
                  color: $mainBlue;
              }
          }
      }
  }

  &_secTab {
      &_box {
          position: absolute;
          display: none;
          z-index: 3;
          background: #fff;
          width: 100px;
          left: 0px;
          box-shadow: 0px 3px 3px rgba(0, 0, 0, .1);
      }

      &_item {
          line-height: 30px;
          text-align: center;


      }
  }

  &_nav {
      &_box {
          float: left;
          width: 232px;
          height: 360px;
          position: relative;
          z-index: 2;
          background: #439bff;
      }

      &_item {
          cursor: pointer;

          // position: relative;
          &:first-child {
              background: #3168c5;
          }

          width:100%;
          height: 40px;
          text-align: center;
          // letter-spacing: 5px;
          color: #fffefe;
          line-height: 40px;
          font-size:16px;

          &:hover {
              font-size: bold;
              background: #3168c5;

              // opacity: .8;
              .index_secNav_box {
                  display: block;
              }
          }
      }
  }

  &_secNav {
      &_box {
          position: absolute;
          display: none;
          float: left;
          left: 232px;
          top: 0;
          width: 280px;
          height: 360px;
          text-align: left;
          background: #fff;
          padding-top: 20px;
          box-sizing: border-box;
          box-shadow: 0px 0px 3px rgba(0, 0, 0, .2);
          overflow: hidden;
      }

      &_item {
          margin-left: 30px;
          line-height: 36px;
          display: inline-block;
          width: 110px;

          &:nth-child(2n) {
              margin-left: 10px;
          }

          a {
              color: #333;
              @include ellipsis();
              display: inline-block;
              width: 110px;
              line-height: 28px;
              line-height: 28px;
              letter-spacing: 2px;
          }

          &:hover a {
              color: $mainBlue;
          }
      }
  }


}

.message {
  &-dialog {
    position: fixed;
    width: 397px;
    height: 50px;
    border: 1px solid #66c33a;
    background: #f1f9eb;
    line-height: 48px;
    box-sizing: border-box;
    top: -80px;
    left: 0;
    right: 0;
    margin: 0 auto;
    border-radius: 6px;
    transition: all 0.3s ease;
    z-index: 103;

    &.success {
      border: 1px solid #66c33a;
      background: #f1f9eb;
    }

    &.error {
      border-color: #f1b4b4;
      background: #f7e5e5;
      color: #ec7373;

      .message-text {
        &:before {
          background: url(../images/img/icon-error.png);
        }
      }
    }

    &.warning {
      border-color: #faecd8;
      background: #fdf6ec;
      color: #e6a23c;

      .message-text {
        &:before {
          background: url(../images/img/icon-warning.png);
        }
      }
    }

    &.notice {
      border-color: #ebeef5;
      background: #edf2fc;
      color: #909399;

      .message-text {
        &:before {
          background: url(../images/img/icon-notice.png);
        }
      }
    }

    &.show {
      top: 50px;
      transition: all 0.3s ease;
    }
  }

  &-text {
    margin-left: 15px;
    font-size: 14px;

    &:before {
      content: '';
      width: 16px;
      height: 16px;
      display: inline-block;
      background: url(../images/img/icon-success.png) no-repeat;
      vertical-align: middle;
      margin-right: 10px;
    }
  }
}

//loading样式
.el-loading-mask {
  position: absolute;
  z-index: 100;
  background-color: hsla(0, 0%, 100%, .9);
  margin: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: opacity .3s;

  &.hide {
    display: none;
  }
}

.el-loading-spinner {
  top: 50%;
  margin-top: -21px;
  width: 100%;
  text-align: center;
  position: absolute;

  .circular {
    height: 42px;
    width: 42px;
    animation: loading-rotate 2s linear infinite;
  }

  .path {
    animation: loading-dash 1.5s ease-in-out infinite;
    stroke-dasharray: 90, 150;
    stroke-dashoffset: 0;
    stroke-width: 2;
    stroke: #409eff;
    stroke-linecap: round;
  }
}

@keyframes loading-rotate {
  100% {
    transform: rotate(1turn);
  }
}

@keyframes loading-dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -40px;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -120px;
  }
}

.footer {
  &_box {
    background-color: #191c20;
  }

  &_wrapper {
    width: 1200px;
    margin: 0 auto;
  }

  &_head {
    color: #fff;
    font-size: 24px;
    line-height: 1;
    padding: 22px 0 25px;
  }

  &_link {
    &_item {
      color: #666;
      font-size: 16px;
      margin-right: 40px;

      &:hover {
        color: #999;
      }
    }
  }

  &_content {
    padding-top: 16px;
    color: #999;
    line-height: 34px;
    font-size: 16px;
    width: 524px;
    float: left;
    margin-right: 50px;

    a {
      color: #999;

      &:hover {
        color: #fff;
      }
    }
  }

  &_QRcode {
    &_item {
      float: left;
      margin-left: 28px;
      margin-top: 25px;

      &:first-child {
        margin-left: 0;
      }
    }

    &_title {
      text-align: center;
      margin: 18px 0 30px;
      font-size: 14px;
      line-height: 1;
      color: #666;
    }
  }

  &_qrcode {
    width: 88px;
    height: 90px;
  }

  &_yyzz {
    float: left;
    margin-top: 40px;
    margin-left: 55px;

    img {
      width: 64px;
      height: 91px;
      vertical-align: bottom;
    }

    &_title {
      font-size: 14px;
      color: #fff;
      line-height: 1;
      padding-top: 7px;
    }
  }

  &_zgzs {
    float: left;
    margin-left: 10px;
    margin-top: 96px;
  }

  &_Copyright {
    background-color: #000000;
    height: 70px;
    color: #666;
    font-size: 16px;
    text-align: center;
    width: 100%;
    line-height: 70px;
  }
}

.search {
  &_box {
    height: 90px;
    font-size: 0;
    width: 1200px;
    margin: 0 auto;
  }

  &_logo {
    width: 136px;
    height: 52px;
    margin: 19px 50px 18px 0;
  }

  &_main {
    display: inline-block;
    width: 540px;
    border: 1px solid $mainBlue;
    margin: 25px 0 25px 74px;

    &_input {
      font-size: 16px;
      padding: 0 20px;
      width: 412px;
      line-height: 38px;
      box-sizing: border-box;
      vertical-align: top;
    }

    &_btn {
      background: $mainBlue;
      color: #fff;
      width: 128px;
      height: 38px;
      line-height: 38px;
      text-align: center;
      display: inline-block;
      font-size: 18px;

      &:hover {
        background: $hoverBlue;
      }
    }

    &_phone {
      font-family: 'MicrosoftYaHei';
      font-size: 16px;
      font-weight: bold;
      font-style: italic;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #3168c5;
      display: inline-block;
      width: 318px;
      line-height: 26px;
      text-align: right;
      position: relative;
      margin-left: 36px;
      margin-top: 16px;
      vertical-align: top;

      &:before {
        content: '';
        width: 57px;
        height: 47px;
        display: inline-block;
        position: absolute;
        background: url(../images/phone.png);
        left: 40px;
      }

      span {
        display: inline-block;
      }
    }
  }
}
.c {
  clear: both;
}
//合作伙伴
.partners {
  &_box {
    background-color: #23272b;
    padding-bottom: 49px;
  }

  &_wrapper {
    width: 1200px;
    margin: 0 auto;
  }

  &_head {
    font-size: 24px;
    line-height: 80px;
    color: #fff;
    width: 1200px;
    margin: 0 auto;
  }

  &_item {
    float: left;
    width: 200px;
    opacity: .4;
    text-align: center;
    height: 64px;
    border-right: 1px solid #000;
    border-top: 1px solid #000;
    box-sizing: border-box;
    // &:nth-child()
    @for $i from 1 through 6 {
      &:nth-child(#{$i}) {
        border-top: none;
      }
    }
    @for $i from 7 through 12 {
      &:nth-child(#{$i}) {
        line-height: 64px;

        .partners_img {
          vertical-align: bottom;
        }
      }
    }

    &:nth-child(6) {
      border-right: none;
    }

    &:nth-child(12) {
      border-right: none;
    }
  }

  &_img {
    vertical-align: middle;
  }
}

//热报课程
.hot_course{
  margin-top: 35px;
  .hot_head{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      height: 40px;
      border-bottom: 1px solid #e5e5e5;
      .hot_name{
          font-size: 20px;
          height: 26px;
          line-height: 26px;
          margin: 7px 0;
          padding-left: 10px;
          color: #050505;
          border-left: 4px solid $mainBlue;
      }
      .hot_menu{
          display: flex;
          flex-direction: row;
          font-size: 16px;
          color: $fontColor;
          align-items: center;

          .hot_menu_item{
              margin-right: 4px;
              width: 16px;
              height: 16px;
              line-height: 16px;
              text-align: center;
              background-color: #e5e5e5;
              border-radius: 2px;
              cursor: pointer;
          }
          .next{
              margin-right: 10px;
          }
          .disabled{
              cursor: default;
              opacity: 0.6;
          }

      }
  }
  .hot_container{
      height: 184px;
      width: 100%;
      padding: 10px 0 20px;
      position: relative;
      .course_item{
          margin-top: 5px;
          width: 224px;
          margin-right:20px;
          &:hover{
            .course_des{
              color: $mainBlue;
            }
          }
          .course_img_box{
              width: 224px;
              height: 158px;
              @include hoverTransform();
              img{
                  width: 100%;
                  height: 100%;
              }
          }
          .course_des{
              font-size: 14px;
              color: $fontColor;
              line-height: 32px;
              &:hover{
                  color: $mainBlue;
              }
              @include ellipsis();
          }
         
      }
      .swiper-slide:last-child .course_item {
          margin-right: 0;
      }
  }

}


//gucommon

@import "./core/color";
@import "./core/method";
.main{
  &_list {
    margin-top: 13px;
    padding-bottom: 15px;
    border-bottom: 1px solid $userBorder;
    display: block;
    &:nth-last-child(1) {
      border-width: 0;
    }

    &_img {
      float: left;
      width: 196px;
      height: 114px;
    }

    &_cont {
      cursor: pointer;
      float: left;
      margin-left: 10px;

      p {
        font-size: 14px;
      }

      &:hover {
        .main_list_title {
          color: $mainBlue;
         
       
        }
      }
    }

    &_title {
      font-size: 18px;
      max-width: 480px;
      //  @include ellipsis();
      .tage {
        display: inline-block;
        color: $white;
        width: 80px;
        text-align: center;
        line-height: 31px;
        background-color: $mainBlue;
        margin-right: 10px;
        font-size: 14px;
      }
    }

    &_time {
      .sum {
        display: inline-block;
        color: $mainBlue;
        padding-left: 5px;
      }
    }

    &_price {
      color: $mainRed;
    }

    &_btn {
      float: right;
      margin: 30px 15px 0 0;
      display: inline-block;
      text-align: center;
      width: 122px;
      font-size: 14px;
      line-height: 42px;
      border-radius: 21px;
      border: solid 1px $userBorder;
      transition: all .3s ease;

      &:hover {
        background-color: $mainBlue;
        color: #fff;
        border: solid 1px $mainBlue;

      }
    }
  }
}

// 个人中心 顶部 公共样式
.centerTop {
  color: $white;
  padding: 14px 0;
  background-color: $mainBlue;

  &_left {
    float: left;
    cursor: default;
  }

  &_right {
    float: right;

    .smallBtn {
      @include smallBtn(122px, 42px, $mainRed);
      box-shadow: 0px 2px 2px 0px rgba(18, 70, 145, 0.58);
      border-radius: 21px;
      margin-top: 30px;

    }
    .integral.gray{
      background-color: #aaa;
    }
  }

  &_tx {
    width: 107px;
    height: 107px;
    display: inline-block;
    vertical-align: middle;
    border: 3px solid $white;
    border-radius: 50%;
    box-sizing: border-box;
  }

  &_name {
    font-size: 20px;
    margin-left: 20px;
  }

  &_time {
    margin-left: 22px;
  }

  &_li {
    cursor: default;
    float: left;
    width: 112px;
    font-size: 18px;
    position: relative;
    padding: 10px 0 0 10px;
    &:nth-child(2){
      .rule{
        left: -88px;
        width: 269px;
   
        &::before{
          left: 127px;
        }
      }
    }
    &:nth-child(3){
      .rule{
        left: -63px;
        width: 233px;
        &::before{
          left: 105px;
        }
      }
    }
    .rule{
      text-align: justify;
      display: none;
      position: absolute;
        top: 115px;
      left: -77px;
      width: 244px;
      padding: 5px 10px;
      color: black;
      font-size: 14px;
      border-radius: 5px;
      background-color: #fff;
      box-shadow: -1px 2px 5px 0px 
      rgba(0, 0, 0, 0.25); 
      z-index: 9;
      &.showRule{
        display: block;
      }
      &:before{
        content: "";
        position: absolute;
        top: -5px;
        left: 118px;
        border-bottom:5px solid $white;
        border-left:5px solid transparent;
        border-right:5px solid transparent;
      }
    }
   
    p {
      line-height: 35px;
    }
    .icon{
      cursor: pointer;
      display: inline-block;
      margin-left: 23px;
    }

    &:after {
      position: absolute;
      bottom: 4px;
     right: 15px;
    content: "";
     height: 105px; 
    border-left: 1px solid #fff;
    transform: rotate(20deg);
    }
    
  }
 
}
.centerTop_li:nth-last-child(2):after {
  height: 0;
}
// 个人中心 左侧 公共样式
.center_content {
  margin: 20px auto 0 !important;

}
.center_left_nav {
    width: 158px;
    background-color: $bgColor;
}
.center_left_nav a {
  cursor: pointer;
  display: block;
  text-align: center;
  width: 158px;
  line-height: 55px;
  font-size: 20px;
  color: $black;

  &.cur {
    color: $white;
    background-color: $mainBlue;
  }

  &:hover {
    color: $white;
    background-color: $mainBlue;
  }
}
// 顶部状态栏
.head_box {
  height: 60px;
  line-height: 58px;
}

.head_wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  -webkit-box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.1);
  background: #fff;
  font-size: 14px;
  color: #505153;
  z-index: 3;
}

.head_container {
  width: 1200px;
  margin: 0 auto;
  a{
    .nav_logo{
      float: left;
      margin: 10px 0;
      height: 40px;
    }
  }
  .head_link_box2{
    float: left;
    font-size: 18px;
	line-height: 55px;
  color: #050505;
  .head_link_item2{
    margin-left: 30px;
    &:hover{
      color: $mainBlue;
    }
  }
  }
}

.head_xiaoxun {
  float: left;
}



.head_link_box {
  float: left;
  text-align: center;
  margin-left: 110px;
}

.head_link_item:hover {
  color: #3168c5;
}

.head_link_item:before {
  content: '';
  width: 1px;
  height: 12px;
  display: inline-block;
  background: #505153;
  margin: 0 9px;
}

.head_link_item:first-child:before {
  width: 0;
  margin: 0;
}
// 登录
.nav_checkLoginBox{
  float: right;
  .navs{
    float: left;
    padding-left: 10px;
  }
  .nav{
    float: left;
    padding-left: 10px;
    box-sizing: border-box;
    // width: 143px;

    &:hover{
      border-left:1px solid $userBorder;
      border-right:1px solid $userBorder;
      background-color: #fbfbfb;
      .down-list{
        display: block;
      }
      .down_icon{
        transform: rotate(90deg);

      }
    }
  }
  .nav_link{
    display: block;
    position: relative;
    color: $mainBlue;
    cursor: pointer;
    em{
      display: inline-block;
      height: 11px;
      border-right: 1px solid $mainBlue;
      margin-left: 5px;
    }
 
  }

}

.mine-nav{
  position: relative;
  padding-right: 20px;
  .xhdLogin{
    margin-top: 5px;
    width: 48px;
    height: 48px;
    border-radius: 50%;
  }
  .down_icon{
    display: block;
    transform: rotate(-90deg);
    position: absolute;
    top: 1px;
    right: -12px;
    transition: all 0.4s ease;
  }
  .down-list{
    display: none;
    position: absolute;
    top: 58px;
    left: 0;
    z-index: 9;
    width:143px;
    text-align: center;
    background-color: #fbfbfb;
    padding: 5px 0 10px;
    border-left:1px solid $userBorder;
    border-right:1px solid $userBorder;
    border-bottom:1px solid $userBorder;
    box-sizing: border-box;
    .list{
      line-height: 30px;
      cursor: pointer;
      &:hover .list-link{
        color: $mainBlue;
        text-decoration: underline;
      }
    }
  }
}
// 提示
#msgTips {
  position: fixed;
  top: 20px;
  left: 50%;
  z-index: 99999999;
  transform: translate(-50%, -150%);
  padding: 12px;
  width: 80%;
  max-width: 380px;
  border: 1px solid #fedddd;
  border-radius: 3px;
  background-color: #fee;
  font-size: 16px;
  color: #fa5555;
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s, transform 0.4s;
}

#msgTips.success {
  color: #67c23a;
  background-color: #f0f9eb;
  border-color: #e1f3d8;
}

#msgTips.fade-in {
  opacity: 1;
  transform: translate(-50%, 0);
}
// 暂无数据
.noData{
  margin-top: 30px;
  text-align: center;
  line-height: 30px;
  font-size: 16px;
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
  .icon{
    display: block;
    margin-right: 10px;
  }
}

